import { useQuery } from 'react-query';
import request from '../helpers/request';
import Storage from '../helpers/storage';

export default function useJwt({ user }) {
  return useQuery(
    ['jwt'],
    async () => {
      const { jwt: storedJwd } = Storage.get('hs_user') || {};
      if (!storedJwd && user.jwt) throw new Error('No jwt in storage. Must be loggedout');
      const headers = { Authorization: 'Hauspass ' + (user?.jwt || storedJwd) };
      const { jwt, error } = await request(`/user/refresh-token`, `get`, null, { headers });
      if (error && user?.jwt) {
        const headers = { Authorization: 'Hauspass ' + storedJwd };
        const { jwt, error: secondError } = await request(`/user/refresh-token`, `get`, null, {
          headers
        });
        if (secondError) throw secondError;
        return jwt;
      } else if (error) {
        throw error;
      }
      return jwt;
    },
    {
      enabled: Boolean(user?.jwt) || Boolean(Storage.get('hs_user')?.jwt),
      refetchInterval: 1000 * 60 * 29,
      refetchOnWindowFocus: true,
      retry: false
    }
  );
}
