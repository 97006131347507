/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import notFound from '../components/404';

const rolesLevel = {
  sudo: 10,
  admin: 6,
  employee: 4,
  sandbox: 3,
  visitor: 2,
  user: 1
};

function isAuthorized(requiredRole = 'employee', role = 'user') {
  return (rolesLevel[requiredRole] || 5) <= (rolesLevel[role] || 0);
}

export default function usePermission(user, { role = 'user', resiliant = false, state } = {}) {
  const { role: currentRole } = user || {};
  const navigate = useNavigate();
  const location = useLocation();

  const [notFoundComponent, setNotFound] = useState(undefined);

  useEffect(
    (_) => {
      const path = location?.pathname;
      if (!resiliant && !user?.jwt && path.indexOf('/login') === -1) {
        navigate('/login', { state: state || { backgroundLocation: location } });
      }
      if (!isAuthorized(role, currentRole)) {
        setNotFound(notFound);
      } else setNotFound(undefined);
    },
    [user, location.pathname]
  );

  return { component: notFoundComponent, isAuthorized: Boolean(notFoundComponent) };
}
