/* eslint-disable react-hooks/exhaustive-deps */
import './stylesheets/App.scss';
import './stylesheets/inputs.scss';
import { Suspense, lazy, useState, useEffect, useMemo } from 'react';
import { Routes, Route, useLocation, Outlet, Link } from 'react-router-dom';
import Storage from './helpers/storage';
import request from './helpers/request';
import Modal from './components/modal';
import Loading from './components/loading';
import Status from './components/status';
import useJwt from './hooks/useJwt';
import Users from './pages/users';
import notFound from './components/404';
import { BroadcastChannel } from 'broadcast-channel';

const Login = lazy(() => import('./pages/users/login'));
const Confirm = lazy(() => import('./pages/users/confirm'));
const Scrap = lazy(() => import('./pages/scrap'));
const Estates = lazy(() => import('./pages/estates'));
const Nav = lazy(() => import('./components/menu'));

function App() {
  const [user, setUser] = useState(Storage.get('hs_user'));
  const location = useLocation();
  const state = location.state;
  const { data: jwt, error: jwtError } = useJwt({ user });

  const channel = useMemo(() => new BroadcastChannel('log'), [user]);

  channel.onmessage = (msg) => {
    const json = JSON.parse(msg);
    const { user: newUser, logout } = json;
    if (user && logout) {
      setUser(undefined);
    } else if (!user && newUser) {
      setUser(newUser);
    }
  };

  const loginPage = useMemo((_) => <Login onLogin={handleLogin} user={user} />, [user]);

  useEffect(() => {
    if (user?.jwt && !user.username) {
      const headers = { Authorization: 'Hauspass ' + user?.jwt };
      request(`/user`, `get`, null, { headers }).then((result) => {
        if (result.error) {
          handleLogout();
        } else {
          handleLogin({ ...result.user, jwt: user.jwt });
          channel.postMessage(JSON.stringify({ user: { ...result.user, jwt: user.jwt } }));
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;
    if (jwtError) handleLogout();
    else if (jwt) handleLogin({ ...user, jwt });
  }, [jwt, jwtError]);

  function handleLogin(user) {
    Storage.add('hs_user', user);
    setUser(user);
  }

  function handleLogout() {
    Storage.remove('hs_user');
    setUser(undefined);
    channel.postMessage(JSON.stringify({ logout: true }));
  }

  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <Nav user={user} logout={handleLogout} />
        <Status user={user} logout={handleLogout} />
        <header className="App-header">
          <Link to="/">
            <h1>haus</h1>
            <h1>pass</h1>
          </Link>
          <p>
            Fortress
            <br />
            <span className="quote">Citadel v3</span>
          </p>
        </header>
        <Routes location={state?.backgroundLocation || location}>
          <Route
            exact
            path="/"
            element={
              <div>
                <Outlet />
              </div>
            }
          >
            <Route index element={<Scrap user={user} />} />
            <Route path="/login" element={loginPage} />
            <Route path="/estates/*" element={<Estates user={user} />} />
            <Route path="/verify" element={<Confirm user={user} />} />
            <Route path="/users/*" element={<Users user={user} />} />
            <Route path="*" element={notFound} />
          </Route>
        </Routes>
        {state?.backgroundLocation && (
          <Routes>
            <Route
              exact
              path="/login"
              element={
                <Modal
                  component={
                    <div>
                      <h3>Votre session a expiré.</h3>
                      Veuillez vous reconnecter.
                      {loginPage}
                    </div>
                  }
                />
              }
            />
          </Routes>
        )}
      </Suspense>
    </div>
  );
}

export default App;
