import ky from 'ky';

export default requestBackend;

function getMessage(error) {
  if (Array.isArray(error) || Array.isArray(error?.errors)) {
    return getMessage(error?.[0] || error.errors?.[0]);
  }
  if (error) {
    const messageLabels = ['message', 'msg', 'code'];
    const label = messageLabels.find((label) => error[label]);
    if (label) {
      return error[label];
    }
  }
}

const { NODE_ENV, REACT_APP_API_HOST } = process.env;
const isStage = NODE_ENV !== 'production';
const host = (() => {
  if (isStage) {
    const APIPORT = REACT_APP_API_HOST.split(':').pop();
    const origin = window.location.origin;
    const currentUri = [...origin.split(':').slice(0, -1), APIPORT].join(':');
    return currentUri;
  }
  return REACT_APP_API_HOST;
})();
const defaultHeaders = {
  'User-Agent': 'Hauspass'
};

function getHeaders(headers = {}) {
  let $headers = {};
  headers?.forEach?.((value, key) => ($headers[key] = value));
  return $headers;
}

async function requestBackend(path, method = 'get', body, options = {}) {
  const { headers = {}, json = body, jwt, ...rest } = options;
  const tokenHeaders = jwt ? { Authorization: `Hauspass ${jwt}` } : {};
  const hasProtocol = !host.indexOf('http://') || !host.indexOf('https://');
  const url = `${hasProtocol ? '' : 'https://'}${host}${path}`;
  const request = {
    headers: { ...defaultHeaders, ...headers, ...tokenHeaders },
    ...rest
  };
  try {
    const res = await ky[method.toLocaleLowerCase()](url, json ? { ...request, json } : request);
    const { headers, status } = res;
    const $headers = getHeaders(headers);
    const type = $headers['content-type'];
    if (status === 204) return { $headers };
    if (!type.indexOf('application/json;')) {
      const { error, ...result } = (await res.json()) || {};
      if (error) {
        throw new Error(getMessage(error));
      }
      return { ...result, $headers };
    }
    const blob = await res.blob();
    if (!blob.type.indexOf('image/')) {
      const buffer = await blob.arrayBuffer();
      const image64 = Buffer.from(buffer, 'binary').toString('base64');
      return { image: image64, type: blob.type };
    }
    return { error: 'Is not a json.' };
  } catch (e) {
    const { response = {}, json = await response?.json?.() } = e;
    const { headers } = response;
    const $headers = getHeaders(headers, path === '/user');
    const error = getMessage(json) || getMessage(e);
    if (typeof error === 'string') return { $headers, error };
    return { $headers, error: 'Unexpected Error.' };
  }
}
