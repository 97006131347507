const aesjs = require('aes-js');

const Storage = {
  add,
  remove,
  get,
  reset
};

const AES_KEY = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 ]; // 128-bit key

const decrypt = (cipherHex, counter) => {
  const encryptedBytes = aesjs.utils.hex.toBytes(cipherHex);
  const aesCtr = new aesjs.ModeOfOperation.ctr(AES_KEY, new aesjs.Counter(counter));
  const decryptedBytes = aesCtr.decrypt(encryptedBytes);
  const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  return decryptedText;
};

const encrypt = (messagePlain, counter) => {
  const textBytes = aesjs.utils.utf8.toBytes(messagePlain);
  const aesCtr = new aesjs.ModeOfOperation.ctr(AES_KEY, new aesjs.Counter(counter));
  const encryptedBytes = aesCtr.encrypt(textBytes);
  const cipherHex = aesjs.utils.hex.fromBytes(encryptedBytes);
  return cipherHex;
};

const getAesCounter = () => {
    const counter = parseInt(localStorage.getItem('HS_COUNTER'));
    if (Number.isNaN(counter)) {
      const randomCounter =  Math.floor(Math.random() * 1000000);
      localStorage.setItem('HS_COUNTER', randomCounter);
    }
    return counter;
};

function add(key, data) {
  try {
    const counter = getAesCounter();
    const dataPlain = JSON.stringify(data);
    const dataCipher = encrypt(dataPlain, counter);
    localStorage.setItem(key, dataCipher);
  } catch {
    localStorage.setItem(key, '');
  }
}

function remove(key) {
  localStorage.removeItem(key);
}

function get(key) {
  const itemCipher = localStorage.getItem(key);
  try {
    const counter = getAesCounter();
    const itemPlain = decrypt(itemCipher, counter);
    return JSON.parse(itemPlain);
  } catch {
    return undefined;
  }
}

function reset() {
  localStorage.clear();
}

export default Storage;
