import request from './request';
import { useQuery } from 'react-query';

export function useUsers(user) {
  return useQuery(['users'], () => getUsers(user), {
    enabled: Boolean(user?.jwt)
  });
}

export async function getUsers(user, page = 1) {
  const { jwt } = user || {};
  const headers = { Authorization: `Hauspass ${jwt}` };
  const path = `/users`;
  const { users, error } = await request(path, 'GET', undefined, {
    searchParams: { page },
    headers,
    timeout: 1000 * 60 * 2
  });
  if (error) return [];
  return users;
}

export function useUser(id, user) {
  return useQuery(['user', id], () => getUser(id, user), {
    enabled: Boolean(user?.jwt) && Boolean(id)
  });
}

export async function getUser(id, user) {
  const { jwt, id: requestorId } = user || {};
  if (!id || !jwt) return undefined;
  const headers = { Authorization: `Hauspass ${jwt}` };
  const path = `${id}` === `${requestorId}` ? '/user' : `/users/${id}`;
  const { user: fetchedUser, error } = await request(path, 'GET', undefined, {
    headers,
    timeout: 1000 * 15
  });
  if (error) return undefined;
  return fetchedUser;
}
