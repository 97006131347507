/* eslint-disable react-hooks/exhaustive-deps */
// import { useEffect, useMemo, useState } from 'react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../components/loading';
import Modal from '../../components/modal';
import request from '../../helpers/request';
import { useUser, useUsers } from '../../helpers/users';
import { useForm } from 'react-hook-form';

export default function UsersList({ user }) {
  const { isLoading, data: users, error: usersError } = useUsers(user);
  const [modal, setModal] = useState(false);

  function onClick(event) {
    const { target } = event || {};
    const {
      localName,
      parentNode,
      value: $value,
      attributes: { value: { value = $value } = {} } = {},
      firstChild
    } = target || {};
    if (localName === 'span') return onClick({ target: parentNode });
    const { innerHTML } = firstChild || {};
    if (!value) return;
    setModal({ id: value, username: innerHTML });
  }

  if (isLoading) return <Loading />;
  if (usersError) {
    return <div>Une erreur s'est produite.</div>;
  }
  const usersComponent = (users || []).map(({ id, username, isVerified }, index) => {
    const key = `user-element-${index}`;
    return (
      <Link to={isVerified ? `/users/${id}` : '#'} key={key} onClick={onClick} value={id}>
        <span>{username}</span>
        {!isVerified && <span>🖂</span>}
      </Link>
    );
  });

  function close() {
    setModal(undefined);
  }

  return (
    <div id="users-list">
      <div>
        <Link to="/users/register">
          <button>
            <strong>New user</strong>
          </button>
        </Link>
      </div>
      <div className="list">{usersComponent}</div>
      {modal && (
        <Modal
          closable={true}
          close={close}
          component={<ResendModal user={user} state={modal} close={close} />}
        />
      )}
    </div>
  );
}

function ResendModal({ user, state, close }) {
  const { id } = state || {};
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [email, setEmail] = useState(false);
  const { data: fetchedUser, isLoading: fetchedLoading } = useUser(id, user);
  const { register, getValues } = useForm();
  const registration = register('email');

  const input = useMemo((_) => <input key="resend-input" name="email" {...registration} />, []);

  const fn = useMemo(
    (_) =>
      async function () {
        setError(undefined);
        setLoading(true);
        const { email } = getValues();
        const error = await resendVerifyMail(user, id, email);
        if (error) setError(error.message || error);
        else setDone(true);
        setLoading(false);
      },
    [user]
  );

  if (fetchedLoading || loading) return <Loading />;

  if (!fetchedUser) return <>Une erreur semble s'être produite...</>;

  if (error)
    return (
      <div>
        <span style={{ color: 'red' }}>{error}</span>
        <p>Voulez-vous réessayer ?</p>
        <button onClick={() => setError(false)}>Réessayer</button>
      </div>
    );

  if (done)
    return (
      <div>
        <p>Mail envoyé!</p>
        <button onClick={close}>Merci</button>
      </div>
    );

  return (
    <div>
      <p>L'utilisateur n'est pas vérifié.</p>
      <p>Voulez-vous renvoyer le mail de confirmation à {fetchedUser.username} ?</p>
      <form>
        {email && input}
        {!email && (
          <button onClick={() => setEmail(true)}>Changer l'email de vérification ?</button>
        )}
        <button type="submit" onClick={fn}>
          Renvoyer
        </button>
      </form>
    </div>
  );
}

async function resendVerifyMail(user, id, email) {
  const { jwt } = user || {};
  const headers = { Authorization: `Hauspass ${jwt}` };
  const path = `/users/${id}/resend-email`;
  const { error } = await request(path, 'PATCH', email ? { email } : undefined, {
    headers,
    timeout: 1000 * 60 * 2
  });
  return error;
}
