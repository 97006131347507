/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import request from '../helpers/request';

export default function Status(props) {
  const { user } = props;

  const { data, refetch } = useStatus(user);
  const onChange = useMemo(() => () => {
    const newState = data?.actif ? false : true;
    switchStatus(user, newState).then((_) => {
      refetch();
    });
  });

  const { actif, scrapping } = data || {};
  const showScrapOpt = useMemo(
    (_) => {
      return Boolean(['admin', 'sudo'].includes(user?.role));
    },
    [user]
  );

  let label;
  let className;

  if (scrapping) label = 'Scrapping...';
  else if (actif) label = 'Actif';
  else label = 'Inactif';

  if (!actif && scrapping) className = 'deactivating';

  return (
    <>
      {showScrapOpt && (
        <div id="scrapping-status" className={className}>
          <input type="checkbox" id="status-toggle" checked={Boolean(actif)} onChange={onChange} />
          <label htmlFor="status-toggle">{label}</label>
        </div>
      )}
    </>
  );
}

function useStatus(user) {
  return useQuery(['status'], () => getStatus(user), {
    enabled: Boolean(user?.jwt) && Boolean(['admin', 'sudo'].includes(user?.role)),
    refetchInterval: 1000 * 60,
    refetchOnWindowFocus: true,
    keepPreviousData: false,
    retry: false
  });
}

async function getStatus(user) {
  const headers = { Authorization: 'Hauspass ' + user.jwt };
  const { actif, scrapping, error } = await request(`/nathalie/scrap`, `get`, null, { headers });
  if (error) throw new Error(error);
  return { actif, scrapping };
}

async function switchStatus(user, newState) {
  const headers = { Authorization: 'Hauspass ' + user.jwt };
  return await request(`/nathalie/scrap`, newState ? 'PATCH' : 'DELETE', null, { headers });
}
