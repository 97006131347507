import '../../stylesheets/users.scss';
import { lazy, Suspense } from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Loading from '../../components/loading';
import UsersList from './lists';
import usePermission from '../../hooks/usePermission';

const Register = lazy(() => import('./create'));
const Construction = lazy(() => import('../../components/construction'));
const UserDetail = lazy(() => import('./detail'));

export default function Users({ user }) {
  const location = useLocation();
  const { state, pathname } = location || {};

  const { component } = usePermission(user, { role: 'employee' });

  if (component && pathname !== '/users/settings') return component;

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes
          exact
          path="/"
          element={<Outlet />}
          location={state?.backgroundLocation || location}
        >
          <Route path="/" element={<UsersList user={user} />} />
          <Route path="/register" element={<Register user={user} />} />
          <Route path="/settings" element={<Construction user={user} />} />
          <Route path="/:userId" element={<UserDetail user={user} />} />
        </Routes>
        {state?.backgroundLocation && <Routes></Routes>}
      </Suspense>
    </>
  );
}
