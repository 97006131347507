import { Suspense, useRef, useState } from 'react';
import Loading from './loading';
import { useLocation, useNavigate } from 'react-router-dom';
import Timer from '@anlerandy/timer';

export function Modal(props) {
  const { component, closable = false, close: passedClose = defaultClose } = props || {};
  const location = useLocation();
  const { state } = location || {};
  const modal = useRef();
  const [style, setStyle] = useState(true);
  const { backgroundLocation: { pathname } = {} } = state || {};
  const navigate = useNavigate();
  const timer = new Timer(80, { destroy: false, save: true, verbose: 33 });

  function hide() {
    if (closable) {
      modal.current.classList.add('hidden');
      setStyle(false);
    }
  }

  function defaultClose() {
    navigate(pathname);
  }

  function close(...args) {
    hide();
    if (closable) timer.launchTimer((_) => passedClose(...args));
  }

  return (
    <Suspense fallback={<Loading />}>
      {style && <style>{'html { overflow: hidden }'}</style>}
      <div id="app-modal" ref={modal}>
        <div className="content">{component}</div>
        <div className="back" onClick={close} />
      </div>
    </Suspense>
  );
}

export default Modal;
